import React from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';

import { withTranslation } from 'language/i18n';

const FooterImage = ({ t }) => (
  <div className="image-quote-container">
    <div className="image-quote-container__title">
      <div className="container">
        <div className="row">
          <div className="col-15">
            <h2>{t('homeFooterImageTitle')}</h2>
          </div>
        </div>
      </div>
    </div>
    <div className="image-quote-container__inner">
      <div className="image-quote image-quote--bg-white image-quote--indicator-left" style={{ left: '15%', top: '30%' }}>
        Bu makina iyiMakina.com sayesinde
        {' '}
        <span className="fw-700">30 günde</span>
        {' '}
        satıldı
      </div>
      <div className="image-quote image-quote--indicator-right" style={{ right: '20%', top: '20%', opacity: '.6' }}>
        <span className="fw-700">6 aydır</span>
        {' '}
        satılamıyor
      </div>
      <div className="image-quote image-quote--indicator-center" style={{ right: '38%', top: '38%', opacity: '.8' }}>
        <span className="fw-700">8 aydır</span>
        {' '}
        satılamıyor
      </div>
      <picture>
        <Image
          src="/image/footer-image.png"
          width="1080"
          height="406"
          layout="responsive"
        />
      </picture>
    </div>
  </div>
);

FooterImage.propTypes = {
  t: PropTypes.func,
};

export default withTranslation('common')(FooterImage);
